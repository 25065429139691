<template>
  <div class="fill-heigt pt-0" fluid>
    <v-row>
      <v-col cols="11" md="7">
        <div class="text-left black--text" style="margin-top:3%">
          {{ $t("clients") }}
        </div>
      </v-col>
      <v-col cols="1" md="1" class="text-right px-0">
        <!-- <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn> -->
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col cols="12" md="12" class="text-right">
        <v-btn
          v-if="accessLevel(currentUser, 'client_add')"
          color="primary"
          @click="dialog = true"
        >
          <v-icon left>mdi-plus</v-icon>{{ $t("create_customer") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="getClients"
          :search="textSearch"
          class="elevation-1"
          dense
        >
          <template v-slot:item.actions="{ item }">
            <div class="text-left">
              <v-btn
                v-if="accessLevel(currentUser, 'client_show')"
                color="blue"
                x-small
                height="30px"
                dark
                @click="showRole(item)"
              >
                <v-icon small>mdi-eye-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="accessLevel(currentUser, 'client_edit')"
                color="primary"
                x-small
                height="30px"
                class="ma-1"
                dark
                @click="edit(item)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="accessLevel(currentUser, 'client_delete')"
                color="error"
                x-small
                height="30px"
                class="ma-1"
                dark
                @click="openConfirmeDialog(item.id)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <add-client-dialog
      :client="client"
      :dialog="dialog"
      @close="closeAddDialog()"
    />
    <info-view-dialog
      :dialog="infoDialog"
      :data="infoView"
      @close="infoDialog = false"
    />
    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('clientsDialog')"
      @yes="deleteClient"
      @no="confirm = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </div>
</template>

<script>
import { DELETE_CLIENT_MUTATION } from "./../graphql/Mutation";
import { GET_CLIENTS_ENTITY_QUERY } from "./../graphql/Query";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import InfoViewDialog from "../../../components/InfoViewDialog.vue";
import AddClientDialog from "../components/AddClientDialog.vue";
import Client from "@/models/Client";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import dialogMixins from "@/mixins/dialog";
import accessLevelMixins from "@/mixins/access-level";
export default {
  name: "Client",
  mixins: [dialogMixins, accessLevelMixins],
  components: {
    InfoViewDialog,
    AddClientDialog,
    ConfirmDialog,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
  },
  data: () => ({
    client: new Client(),
    clients: [],
    infoDialog: false,
    // infoView: {
    //   icon: "mdi-account-tie-outline",
    //   title: "Clientes",
    //   content:
    //     "Adicione os seus clientes, visualize e faça gestão das suas vagas.",
    // },
    textSearch: "",
  }),
  apollo: {
    clients: {
      query: GET_CLIENTS_ENTITY_QUERY,
      fetchPolicy: 'no-cache'
    },
  },
  watch: {
    clients: function(val) {
      this.setClientsState(val);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getClients: "client/getClients",
    }),
      infoView() {
        return {
          icon: "mdi-account-tie-outline",
          title: this.$t("clients"),
          content: this.$t("clientsinfo"),
        };
      },
    headers() {
      return [
        {
          text: this.$t("form_candidate_name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: this.$t("form_user_phone"), value: "telephone" },
        { text: this.$t("address"), value: "address" },
        {
          text: this.$t("actions"),
          align: "left",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      removeClient: "client/removeClient",
      setClientsState: "client/setClients",
    }),
    closeAddDialog() {
      this.client = new Client();
      this.show = false;
      this.dialog = false;
    },
    async deleteClient(id) {
      this.confirm = false;
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_CLIENT_MUTATION,
          variables: {
            id,
          },
        });
        this.removeClient(id);
        this.success = this.$t("clientSuccess");
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    edit(client) {
      this.client = client;
      this.dialog = true;
    },
    openConfirmeDialog(id) {
      this.removeId = id;
      this.confirm = true;
    },
    showRole(client) {
      this.client = client;
      this.show = true;
      this.dialog = true;
    },
  },
};
</script>
